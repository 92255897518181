import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./BuyBox.module.scss";
import DeviceContext from "src/contexts/DeviceContext";
import TranslationsContext from "src/contexts/TranslationsContext";
import Spinner from "../Spinner/Spinner";
import { newMetricsWithContext } from "src/utils/metricsUtils";
import { interpolateTranslation } from "../Translations/Translations";
import debug from "src/utils/debugUtils";
import { getBbMetadata } from "src/utils/asinMetadataUtils";
import { NativeAPI } from "src/utils/deviceUtils";
import { AudibleInfo } from "./AudibleInfo";
import { AudibleConditionsOfUse } from "./AudibleConditionsOfUse";
import { PointsDetailsInfo } from "./PointsDetailsInfo";
import { CONTENT_TYPE } from "src/utils/asinUtils";
import SubscriptionUpsell, { SUBSCRIPTION_TYPE } from "./SubscriptionUpsell";
import FirstReadsUpsell from "./FirstReadsUpsell";
import BorrowManagerContext, { onFailure, onSuccess } from "src/contexts/BorrowManagerContext";

type PropTypes = {
    data?: QuickViewAsinMetadata;
    getter?: (asin: string) => Promise<QuickViewAsinMetadata>;
    dismiss?: () => void;
    setIsInfoSheet?: (isInfoSheet: boolean) => void;
};

enum InfoSheet {
    AUDIBLE_INFO,
    AUDIBLE_CONDITIONS_OF_USE,
    POINTS_DETAILS_INFO,
    NONE,
}

enum TYP {
    PURCHASE,
    PRE_ORDER,
    NONE,
}

const maybeStopPropagation = (event: React.UIEvent) => {
    const ct = event.currentTarget;
    if (ct.scrollTop || ct.scrollHeight !== ct.clientHeight) {
        event.stopPropagation();
    }
};

const openKindleStoreTermsOfUse = () => NativeAPI.openWebPage('/gp/help/customer/display.html?nodeId=201014950');

const getLocalePriceString = (locale: string, price?: CurrencyInfo) => price?.amount?.toLocaleString(locale, { style: 'currency', currency: price.currency });

const getPriceData = (locale: string, domain: string, option?: AapiBuyingOption ) => {
    if (!option?.price) { return {}; }

    const priceGroup = option.price.entity;

    return {
        savingPrice: getLocalePriceString(locale, priceGroup?.savings?.money),
        savingsPercentage: priceGroup?.savings?.percentage?.displayString,
        points: option.points?.entity?.totalAmount?.points?.amount,
        basisPriceLabel: priceGroup?.basisPrice?.label,
        basisPriceString: getLocalePriceString(locale, priceGroup?.basisPrice?.moneyValueOrRange?.value),
        priceToPayLabel: priceGroup?.priceToPay?.label,
        priceToPayString: getLocalePriceString(locale, priceGroup?.priceToPay?.moneyValueOrRange?.value),
        shouldDisplayVATIncludedMessage: false,
        shouldDisplayVATEBookPBookDifferenceMessage: false,
        shouldDisplaySellerOfRecord: false,
        sellerOfRecord: option.merchant?.entity?.merchantName,
        shouldDisplayCommissionaireMessage: true,
        isAgencyWithTax: false,
    };
};

const getKuUpsellJoinProgram = (buyingOptions?: [AapiBuyingOption]): AapiJoinProgram | undefined => {
    return buyingOptions?.find(buyingOption => buyingOption.type === "KINDLE_UNLIMITED_UPSELL")?.callToAction?.entity?.joinProgram;
};

const getCuUpsellJoinProgram = (buyingOptions?: [AapiBuyingOption]): AapiJoinProgram | undefined => {
    return buyingOptions?.find(buyingOption => buyingOption.type === "COMICS_UNLIMITED_UPSELL")?.callToAction?.entity?.joinProgram;
};

const OrLine: React.FC = () => {
    const translations = useContext(TranslationsContext);
    return (<div className={styles.orRow}>
        <div className={styles.fakeHrWrapper}>
            <div className={styles.fakeHr}/>
        </div>
        <div className={styles.orText}>{translations.getText("buying-options-or")}</div>
        <div className={styles.fakeHrWrapper}>
            <div className={styles.fakeHr}/>
        </div>
    </div>);
};

export const BuyBoxContent: React.FC<PropTypes> =  ({ data, getter, setIsInfoSheet, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const translations = useContext(TranslationsContext);
    const borrowManager = useContext(BorrowManagerContext);
    const asin = data?.asin ?? "";
    const metrics = newMetricsWithContext("BuyBox", asin);
    const [buyButtonEnabled, setBuyButtonEnabled] = useState(false);
    const [actionInProgress, setActionInProgress] = useState(false); // Used to prevent multiple taps triggering multiple AJAX calls
    const [infoSheet, setInfoSheet] = useState(InfoSheet.NONE);
    const [thankYouPage, setThankYouPage] = useState(TYP.NONE);
    const [addNarration, setAddNarration] = useState(false);
    const [hasFetchedMetadata, setHasFetchedMetadata] = useState(false);
    const [metadata, setMetadata] = useState(data);
    const fetchFailuresCount = useRef(0);
    const fetchFailureError = useRef("");
    const [alcBuyingOption, setAlcBuyingOption] = useState<AapiBuyingOption | undefined>(data?.primaryPurchaseBuyingOption)
    const [kuUpsellJoinProgram, setKuUpsellJoinProgram] = useState<AapiJoinProgram | undefined>(getKuUpsellJoinProgram(data?.allBuyingOptions));
    const [cuUpsellJoinProgram, setCuUpsellJoinProgram] = useState<AapiJoinProgram | undefined>(getCuUpsellJoinProgram(data?.allBuyingOptions));
    const waitStartedRef = useRef(Date.now());

    const actualGetter = getter || getBbMetadata;

    useEffect(() => {
        if (!hasFetchedMetadata) {
            debug.log(`Fetching buy box metadata for ${asin}`);
            setHasFetchedMetadata(true);
            actualGetter(asin).then((data) => {
                if (data.loadFailed) {
                    fetchFailureError.current = data.error || "unknown";
                    if (fetchFailuresCount.current === 0) {
                        metrics.recordOperationalMetric("fetchRetryWasTriggered", 1, fetchFailureError.current);
                    }
                    fetchFailuresCount.current += 1;
                    setHasFetchedMetadata(data.unrecoverableError === true);
                } else {
                    if (fetchFailuresCount.current > 0) {
                        metrics.recordOperationalMetric("fetchRetryWasSuccessful", 1, fetchFailureError.current);
                        metrics.recordOperationalMetric("fetchRetryWasSuccessfulAfterCount", fetchFailuresCount.current, fetchFailureError.current);
                    }
                    fetchFailuresCount.current = 0;
                }
                setMetadata((old) => {
                    return {...old, ...data };
                });
                setAlcBuyingOption(data.primaryPurchaseBuyingOption);
                setKuUpsellJoinProgram(getKuUpsellJoinProgram(data.allBuyingOptions));
                setCuUpsellJoinProgram(getCuUpsellJoinProgram(data.allBuyingOptions));
            });
        }
    }, [hasFetchedMetadata, getter, asin, metrics, actualGetter]);

    useEffect(() => {
        setBuyButtonEnabled(!!alcBuyingOption);
    }, [alcBuyingOption]);

    const isLongWait = () => {
        const elapsed = Date.now() - waitStartedRef.current;
        return (elapsed > 2500);
    };

    const getErrorMessage = () => {
        if (!metadata?.loadFailed) {
            return;
        }
        if (metadata.unrecoverableError) {
            return (
                <div className={styles.loadFailure}>
                    <p>
                        <i>&quot;Failure is the condiment that gives success its flavor.&quot;</i>
                        <br/>
                        <b>— Truman Capote</b>
                    </p>
                    <br/>
                    {translations.getText("unrecoverable-failure-message")}
                </div>
            );
        }
        if (navigator.onLine === false) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("no-internet-connection-title")}
                    <br/>
                    <br/>
                    {translations.getText("no-internet-connection-message")}
                </div>
            );
        }
        if (isLongWait()) {
            return (
                <div className={styles.loadFailure}>
                    {translations.getText("long-request-message")}
                </div>
            );
        }
    };

    const handleInfoSheet = (infoSheet: InfoSheet) => {
        setIsInfoSheet?.(infoSheet !== InfoSheet.NONE);
        setInfoSheet(infoSheet);
    }

    const clearInfoSheet = () => handleInfoSheet(InfoSheet.NONE);


    const toggleAddNarration = () => {
        setAddNarration(!addNarration);
    };

    const offer = metadata?.primaryPurchaseBuyingOption;
    const isPreOrder = metadata?.canPreOrder && !metadata.canBuy;
    debug.log(`isPreOrder: ${isPreOrder}`);
    debug.log(offer);

    const handlePrimaryCtaClick = () => {
        if (actionInProgress) {
            return;
        }
        setActionInProgress(true);
        borrowManager.redeemOfferByActionType(asin, isPreOrder ? "Preorder" : "Buy")
            .then(onSuccess((result) => {
                debug.log(`success`);
                debug.log(result);
                setThankYouPage(isPreOrder ? TYP.PRE_ORDER : TYP.PURCHASE);
                setActionInProgress(false);
            }))
            .then(onFailure((result) => {
                debug.error(`failure for ${asin} ${isPreOrder ? "Preorder" : "Buy"}`);
                debug.error(result);
                setActionInProgress(false);
            }));
    };

    const handleReadNowClick = () => {
        NativeAPI.openBook(asin, metadata?.title, metadata?.authors, CONTENT_TYPE.EBOK);
    };

    const priceIfOwnsCompanion = metadata?.whisperSyncForVoice?.companionBook?.priceIfOwnsCompanion;
    const addNarrationPrice = priceIfOwnsCompanion?.amount?.toLocaleString(deviceContext.locale, { style: 'currency', currency: priceIfOwnsCompanion.currency });

    const getPrimaryCtaText = () => {
        const oneClickDisplayString = metadata?.primaryPurchaseBuyingOption?.callToAction?.entity?.oneClick?.data?.displayString;
        if (oneClickDisplayString) {
            return oneClickDisplayString;
        }
        const oneClickPreorderDisplayString = metadata?.primaryPurchaseBuyingOption?.callToAction?.entity?.oneClickPreorder?.data?.displayString;
        if (oneClickPreorderDisplayString) {
            return oneClickPreorderDisplayString;
        }

        if (alcBuyingOption?.callToAction?.entity?.oneClick) { return translations.getText("buy-now"); }
        if (alcBuyingOption?.callToAction?.entity?.oneClickPreorder) {
            if (deviceContext.domain === "amazon.com") {
                return translations.getText("preorder-with-1-click");
            }
            return translations.getText("preorder-now");
        }
        return "";
    };

    const primaryCtaText = getPrimaryCtaText();

    const priceData = getPriceData(deviceContext.locale, deviceContext.domain, alcBuyingOption);
    debug.log(priceData);

    const isReady = metadata?.loaded === true;

    if (infoSheet !== InfoSheet.NONE) {
        return (<>
            <main
                className={[styles.main, styles.infoSheet, styles[deviceContext.theme]].join(" ")}
                onScroll={maybeStopPropagation}
                onTouchStart={maybeStopPropagation}
                onTouchMove={maybeStopPropagation}
                onTouchEnd={maybeStopPropagation}
                onTouchCancel={maybeStopPropagation}
            >
                {infoSheet === InfoSheet.AUDIBLE_CONDITIONS_OF_USE && (<AudibleConditionsOfUse />)}
                {infoSheet === InfoSheet.AUDIBLE_INFO && (<AudibleInfo />)}
                {infoSheet === InfoSheet.POINTS_DETAILS_INFO && (<PointsDetailsInfo />)}
            </main>
            <footer className={styles.footer}>
            <div className={styles.footerButtons}>
                <button className={styles.backButton} onClick={clearInfoSheet}>
                    <div className={styles.label}>
                        <div className={styles.backChevronIconContainer}>
                            <div className={styles.backChevronIcon} />
                        </div>
                        {translations.getText("back")}
                    </div>
                </button>
            </div>
        </footer>
        </>);
    }

    if (thankYouPage !== TYP.NONE) {
        return (<>
            <main
                className={[styles.main, styles.thankYouPage, styles[deviceContext.theme]].join(" ")}
                onScroll={maybeStopPropagation}
                onTouchStart={maybeStopPropagation}
                onTouchMove={maybeStopPropagation}
                onTouchEnd={maybeStopPropagation}
                onTouchCancel={maybeStopPropagation}
            >
                {thankYouPage === TYP.PURCHASE && (<>
                    <div className={styles.thankYouText}>
                        {translations.getText("thank-you")}
                    </div>
                    <div className={styles.titleIsNowInYourLibrary}>
                        {interpolateTranslation(
                            translations.getText("title-is-now-in-your-library"),
                            "title",
                            (<span className={styles.bookTitle}>
                                {metadata?.title ?? ""}
                            </span>)
                        )}
                    </div>
                </>)}
                {thankYouPage === TYP.PRE_ORDER && (<>
                    <div className={styles.thankYouText}>
                        {translations.getText("order-placed-thanks")}
                    </div>
                    <div className={styles.preOrderConfirmationEmail}>
                        {translations.getText("confirmation-will-be-sent-to-your-email")}
                    </div>
                </>)}

            </main>
            <footer className={styles.footer}>
            <div className={styles.footerButtons}>
                {thankYouPage === TYP.PURCHASE && (<>
                    <button className={styles.readNowButton} onClick={handleReadNowClick}>
                        {translations.getText("read-now")}
                    </button>
                </>)}
                {thankYouPage === TYP.PRE_ORDER && (<>
                    <button className={styles.okButton} onClick={dismiss}>
                        {translations.getText("ok")}
                    </button>
                </>)}
            </div>
        </footer>
        </>);
    }

    return (<>
    <main
        className={[styles.main, styles[deviceContext.theme]].join(" ")}
        onScroll={maybeStopPropagation}
        onTouchStart={maybeStopPropagation}
        onTouchMove={maybeStopPropagation}
        onTouchEnd={maybeStopPropagation}
        onTouchCancel={maybeStopPropagation}
    >
        {!isReady && (
            <div style={{ padding: "20px" }}>
                {metadata?.unrecoverableError !== true && (<Spinner />)}
                { getErrorMessage() }
                {(metadata?.loadFailed) && debug.get('enableLoadFailureMessages') && (
                    <div className={styles.loadFailure}>
                        { metadata.error }
                    </div>
                )}
            </div>
        )}

        {(kuUpsellJoinProgram || debug.get("forceBuyBoxKuUpsell")) && (<>
            <SubscriptionUpsell asin={asin} type={SUBSCRIPTION_TYPE.KINDLE_UNLIMITED} joinProgram={kuUpsellJoinProgram} />
            <OrLine />
        </>)}

        {(cuUpsellJoinProgram || debug.get("forceBuyBoxCuUpsell")) && (<>
            <SubscriptionUpsell asin={asin} type={SUBSCRIPTION_TYPE.COMICS_UNLIMITED} joinProgram={cuUpsellJoinProgram} />
            <OrLine />
        </>)}

        {offer && (<>

            <div className={styles.buyBox}>
                <table className={styles.buyBoxTable}>
                    {priceData.basisPriceLabel && priceData.basisPriceString && (
                        <tr>
                            <td>{priceData.basisPriceLabel}</td>
                            <td>
                                <div className={styles.printListPrice}>
                                    {priceData.basisPriceString}
                                </div>
                            </td>
                        </tr>
                    )}
                    {priceData.priceToPayLabel && priceData.priceToPayString && (
                        <tr>
                            <td>{priceData.priceToPayLabel}</td>
                            <td>
                                <div className={styles.kindlePrice}>
                                    {priceData.priceToPayString}
                                </div>
                                {priceData.savingPrice && priceData.basisPriceString && (
                                    <div className={styles.savings}>
                                        Save {priceData.savingPrice} ({priceData.savingsPercentage})
                                    </div>
                                )}
                                {priceData.shouldDisplayVATIncludedMessage && (
                                    <div className={styles.vatIncludedMessage}>
                                        {translations.getText("vat-included-message")}
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                    {priceData.points && (
                        <tr>
                            <td>{translations.getText("you-earn-label")}</td>
                            <td>
                                <div className={styles.points}>
                                    {translations.formatText("points-with-units", { points: priceData.points })}
                                    &nbsp;
                                    <div className={`${styles.pointsDetailsLink} ${styles.activeText}`} onClick={() => handleInfoSheet(InfoSheet.POINTS_DETAILS_INFO)}>
                                        {translations.getText("points-details-link")}
                                    </div>
                                </div>

                            </td>
                        </tr>
                    )}
                    {priceData.sellerOfRecord && (
                        <tr>
                            <td>{translations.getText("sold-by-label")}</td>
                            <td>
                                <div className={styles.sellerOfRecord}>
                                    {priceData.sellerOfRecord}
                                </div>
                                {priceData.shouldDisplayCommissionaireMessage && (
                                    <div className={styles.commissionaireMessage}>
                                        Price set by seller
                                    </div>
                                )}
                            </td>
                        </tr>
                    )}
                </table>
                {addNarrationPrice && (
                    <>
                        <table className={styles.addNarrationTable}>
                            <tr>
                                <td>
                                    <input type="checkbox" checked={addNarration} onClick={toggleAddNarration}/>
                                </td>
                                <td>
                                    <div className={styles.addNarrationSection}>
                                        Add an <img alt="" src="https://m.media-amazon.com/images/I/01ToaAyiR3L.svg" id="audible-logo"/> <span className={styles.activeText} onClick={() => handleInfoSheet(InfoSheet.AUDIBLE_INFO)}> audiobook</span> with Audible narration for <span className={styles.addNarrationPrice}>{addNarrationPrice}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        {addNarration && (
                            <div className={styles.addNarrationLegalese}>
                                By purchasing this title, you agree to Audible&apos;s <span className={styles.activeText} onClick={() => handleInfoSheet(InfoSheet.AUDIBLE_CONDITIONS_OF_USE)}>Conditions Of Use.</span>
                                <div>
                                    Sold and delivered by Audible, an Amazon company
                                </div>
                            </div>
                        )}
                    </>
                )}
                {isPreOrder && metadata.releaseDate && (
                    <div className={styles.preOrderAutoDeliverText}>
                        {interpolateTranslation(
                            translations.getText("preorder-auto-delivery-text"),
                            "releaseDate",
                            (<span className={styles.releaseDate}>{metadata.releaseDate?.displayString}</span>)
                        )}
                    </div>
                )}
            </div>
            {offer.type === "LIMBER" && (<FirstReadsUpsell asin={asin} buyingOption={offer} />)}
        </>) }

    </main>
    <footer className={styles.footer}>
        <div className={styles.footerButtons}>
            {primaryCtaText && (buyButtonEnabled && !actionInProgress
                ? (<button className={styles.ctaButton} onClick={handlePrimaryCtaClick}>{primaryCtaText}</button>)
                : (<button className={[styles.ctaButton, styles.disabledButton].join(" ")}>{primaryCtaText}</button>)
            )}
        </div>
        <div className={styles.oneClickLegalText}>
            <div>{interpolateTranslation(
                translations.getText("add-to-your-library-for-free-legal-warning"),
                "kindleStoreTermsOfUse",
                <span className={styles.activeText} tabIndex={0} role="button" onClick={openKindleStoreTermsOfUse}>
                    {translations.getText("kindle-store-terms-of-use")}
                </span>
            )}</div>
        </div>
    </footer>
    </>);
};

const BuyBox: React.FC<PropTypes> = ({ data, getter, dismiss }: PropTypes) => {
    const deviceContext = useContext(DeviceContext);
    const itemContainerClassName = `${styles.itemContainer} ${styles[deviceContext.theme]}`;

    return (
        <div className={itemContainerClassName}>
            <div className={styles.chevronIconContainer}>
                <div className={styles.chevronIcon} onClick={dismiss}/>
            </div>
            <BuyBoxContent data={data} getter={getter} dismiss={dismiss} />
        </div>
    );
};

export default BuyBox;
